
<template functional>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <span></span>
        <span class="md:flex hidden items-center">
            <span class="text-sm"> COPYRIGHT &copy; {{ new Date().getFullYear() }} <a href="/" target="_blank" rel="nofollow">Logosocket</a>, All rights Reserved</span>
        </span>
    </footer>
</template>

<script>

export default {
    name: "the-footer",
    props: {
        classes: {
            type: String,
        },
    }
}
</script>
