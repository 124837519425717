<template>
  <span class="flex align-center">
    <feather-icon v-if="activeUser && activeUser.role" icon="MessageSquareIcon" @click="$router.push(`/chat`).catch(() => {})" class="target-message cursor-pointer w-6 h-6 ml-4 mr-2" :badge="unread_chats" />
  </span>
</template>

<script>

export default {
  computed: {
    activeUser() {
      return this.$store.state.AppActiveUser
    },
    unread_chats(){
      return this.$store.state.activeUserUnreadChats
    }
  },
  // created() {
  //   if(!moduleChat.isRegistered) {
  //     this.$store.registerModule('chat', moduleChat)
  //     moduleChat.isRegistered = true
  //   }
  // },
  // beforeDestroy: function() {
  //   this.$store.unregisterModule('chat')
  //   moduleChat.isRegistered = false
  // },
  mounted(){
    // this.$store.dispatch('recallChatCount')
    // Echo.channel("chat-channel-"+this.activeUser.id)
    //   .listen('ChatEvent', (e) => {
    //       if(this.activeUser.id === e.chat.to || this.activeUser.id === e.chat.from){
    //         this.$store.commit('chat/SEND_CHAT_MESSAGE',e.chat)
    //       }
    //       if(this.activeUser.id === e.chat.to){
    //         this.$store.dispatch('recallChatCount')
    //       }
    //   });
  },
}
</script>
