export default [
  {
    url: '/',
    name: "Dashboard",
    icon: "HomeIcon",
    i18n: "Dashboard",
    slug: "dashboard"
  },
  {
    url: null,
    name: "Orders",
    icon: "CalendarIcon",
    i18n: "Orders",
    submenu: [
      {
        url: '/orders?status=pending',
        name: "Pending",
        slug: "orders",
        i18n: "Pending",
      },
      {
        url: '/orders?status=delivered',
        name: "Delivered",
        slug: "orders",
        i18n: "Delivered",
      },
      {
        url: '/orders?status=completed',
        name: "Completed",
        slug: "orders",
        i18n: "Completed",
      },
      {
        url: '/orders?status=refunded',
        name: "Refunded",
        slug: "orders",
        i18n: "Refunded",
      },
    ]
  },
  {
    url: "/chat",
    name: "Chat",
    slug: "chat",
    icon: "MessageSquareIcon",
    i18n: "Chat",
  },
  {
    url: "/account",
    name: "Settings",
    slug: "account",
    icon: "SettingsIcon",
    i18n: "Settings",
  },

]

